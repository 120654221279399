import {RepositoryService} from './base.repo';
import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';
import {Antecedent} from '../models/antecedent';

@Injectable({
  providedIn: 'root',
})
export class AntecedentRepository extends RepositoryService<Antecedent> {
  constructor(
    api: ApiService
  ) {
    super(api, 'antecedents');
  }

  fromJson(obj: any): Antecedent {
    const antecedent = new Antecedent();
    antecedent.id = obj.id;
    antecedent.name = obj.name;
    antecedent.type = obj.type;
    antecedent.date = new Date(obj.date);

    return antecedent;
  }

  toJson(obj: Antecedent) {
    return obj.copy();
  }
}
