import {BaseModel} from './base';

export class BodyPart extends BaseModel {
  code: string;
  name: string;
  order: number;
  selectable: boolean;

  compareTo(obj: BodyPart): number {
    if (this.order > obj.order) {
      return 1;
    }
    if (this.order < obj.order) {
      return -1;
    }
    return 0;
  }

}
