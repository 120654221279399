import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PainsBodyComponent} from './pains-body/pains-body.component';
import {PainsBodyGroupComponent} from './pains-body-group.component';
import {NgxPanZoomModule} from './pan-zoom/panzoom.module';
import {SharedModule} from '../../shared.module';


@NgModule({
  declarations: [
    PainsBodyComponent,
    PainsBodyGroupComponent
  ],
    imports: [
        CommonModule, NgxPanZoomModule, SharedModule
    ],
  exports: [
    PainsBodyGroupComponent
  ]
})
export class PainsBodyGroupModule {
}
