import { Component, OnInit } from '@angular/core';
import { UserFileService } from '../../../../services/user-file.service';
import { UserFile } from '../../../../models/userfile';
import { ServiceRepository } from '../../../../repositories/service.repo';
import { Service } from '../../../../models/service';
import { Division } from '../../../../models/division';
import { DivisionRepository } from '../../../../repositories/division.repo';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css'],
})
export class ManagementComponent implements OnInit {
  userfile: UserFile;
  services: Service[] = [];
  divisions: Division[] = [];

  constructor(
    private userfileService: UserFileService,
    private serviceRepo: ServiceRepository,
    private divisionRepo: DivisionRepository,
  ) {}

  ngOnInit(): void {
    this.userfile = this.userfileService.tempUserfile;
    this.serviceRepo.list().subscribe((services) => {
      this.services = services;
    });
    this.divisionRepo.list().subscribe((divisions) => {
      this.divisions = divisions;
    });
  }

  onOptionSelectService(event: any) {
    const selectedCode = event.target.value;
    this.userfile.service = this.services.find(
      (service) => service.code === selectedCode,
    );
  }

  onOptionSelectDivision(event: any) {
    const selectedCode = event.target.value;
    this.userfile.division = this.divisions.find(
      (division) => division.code === selectedCode,
    );
  }
}
