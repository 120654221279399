import {Component, OnInit} from '@angular/core';
import {Input} from '@angular/core';
import {Priority} from '../../../models/priority';

@Component({
  selector: 'app-prority-element',
  templateUrl: './prority-element.component.html',
  styleUrls: ['./prority-element.component.css']
})
export class ProrityElementComponent implements OnInit {

  @Input() priority: Priority;
  @Input() isSelected: boolean;
  constructor() {
  }

  ngOnInit(): void {
  }

}
