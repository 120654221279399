import {ApiService} from './../services/api.service';
import {BaseModel} from '../models/base';
import {firstValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export abstract class RepositoryService<T extends BaseModel> {
  constructor(protected api: ApiService, private _route: string) {
    this.route = _route;
  }

  route: string;

  abstract fromJson(obj: object): T;

  abstract toJson(obj: T): any;

  findById(id: number): Observable<T> {
    return this.api.findById(this.route, id).pipe(map((o) => this.fromJson(o)));
  }

  list(params: any = {}): Observable<T[]> {
    return this.api
      .list(this.route, params)
      .pipe(map((elements) => elements.map((o) => this.fromJson(o))));
  }


  save(obj: T): Promise<void> {
    return firstValueFrom(this.api.update(this.route, this.toJson(obj)));
  }


}
