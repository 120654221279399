import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChipStatusComponent} from './chip-status.component';
import {SharedModule} from '../../shared.module';


@NgModule({
  declarations: [ChipStatusComponent],
  imports: [
    CommonModule, SharedModule
  ], exports: [ChipStatusComponent]
})
export class ChipStatusModule {
}
