import { Component, OnInit } from '@angular/core';
import { UserFile } from '../../../../models/userfile';
import { UserFileService } from '../../../../services/user-file.service';
import { Antecedent, TypeAntecedent } from '../../../../models/antecedent';

@Component({
  selector: 'app-antecedents',
  templateUrl: './antecedents.component.html',
  styleUrls: ['./antecedents.component.css'],
})
export class AntecedentsComponent implements OnInit {
  userfile: UserFile;

  typeAntecedentSurgery = TypeAntecedent.SURGERY;
  typeAntecedentAllergy = TypeAntecedent.ALLERGY;
  typeAntecedentMedication = TypeAntecedent.MEDICATION;
  typeAntecedentVaccine = TypeAntecedent.VACCINE;
  typeAntecedentAddiction = TypeAntecedent.ADDICTION;

  constructor(private userFileService: UserFileService) {}

  ngOnInit(): void {
    this.userfile = this.userFileService.tempUserfile;
  }

  addAntecedent(antecedent: Antecedent): void {
    this.userfile.patient.antecedents.push(antecedent);
  }

  removeAntecedent(antecedent: Antecedent): void {
    const index = this.userfile.patient.antecedents.indexOf(antecedent);

    if (index > -1) {
      this.userfile.patient.antecedents.splice(index, 1);
    }
  }
}
