import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { Patient } from 'src/app/models/user';
import { UserFile } from '../../../models/userfile';
import { Symptom } from '../../../models/symptom';
import { LoggerService } from '../../../services/logger.service';
import { CssHelper } from '../../../helpers/css.helper';
import { MatDialog } from '@angular/material/dialog';
import { EditDialogComponent } from '../edit-dialog/edit-dialog.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserFileService } from '../../../services/user-file.service';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.css'],
})
export class ProfileInfoComponent implements OnInit, OnDestroy {
  routeQueryParams$: Subscription;

  constructor(
    private logger: LoggerService,
    private userFileService: UserFileService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.routeQueryParams$ = route.queryParams.subscribe((params) => {
      if (params.dialog) {
        this.openDialog();
      }
    });
  }

  @Input()
  patient: Patient;
  @Input()
  userfile: UserFile;
  @Output() tempSelectedSymptom = new EventEmitter<Symptom>();
  @Output() saveUserfile = new EventEmitter<UserFile>();
  waitingStatusSuffixClass: string;

  initNotes: string;

  ngOnInit(): void {
    this.waitingStatusSuffixClass = CssHelper.waitingStatusSuffixClass(
      this.userfile.getWaitingStatus(),
    );
    this.initNotes = this.userfile.notes;
  }

  ngOnDestroy(): void {
    this.routeQueryParams$.unsubscribe();
  }

  focusInSymptom(symptom: Symptom) {
    this.tempSelectedSymptom.emit(symptom);
  }

  focusOutSymptom() {
    this.tempSelectedSymptom.emit();
  }

  getColorPainLevelClass() {
    if (this.userfile.painLevel >= 7) {
      return 'high';
    } else if (this.userfile.painLevel >= 4) {
      return 'medium';
    } else {
      return 'low';
    }
  }

  clicOnBloc(subpage?: string): void {
    const outletSubpage = subpage ?? 'identity';
    this.router.navigate([{ outlets: { 'userfile-edit': outletSubpage } }], {
      relativeTo: this.route.parent,
      queryParams: { dialog: true },
    });
  }

  openDialog(): void {
    this.userFileService.initTempUserFile();
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'userfile-edit-dialog',
      data: { user: this.patient, userfile: this.userfile },
    });

    dialogRef.afterClosed().subscribe((_) => {
      this.saveUserfile.emit();
      this.router
        .navigate([{ outlets: { 'userfile-edit': null } }], {
          relativeTo: this.route.parent,
        })
        .then();
    });
  }

  onFocusOutNotes() {
    if (this.initNotes !== this.userfile.notes) {
      this.logger.debug('notes changes saved', this.userfile.notes);
      this.initNotes = this.userfile.notes;
      this.userFileService.saveUserfile();
    }
  }
}
