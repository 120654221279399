import { PainRepository } from './pain.repo';
import { HospitalRepository } from './hospital.repo';
import { UserFile } from '../models/userfile';
import { RepositoryService } from './base.repo';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Observable } from 'rxjs';
import { ServiceRepository } from './service.repo';
import { Priority } from '../models/priority';
import { DivisionRepository } from './division.repo';
import { PatientRepository, ProfessionalRepository } from './user.repo';

@Injectable({
  providedIn: 'root',
})
export class UserFileRepository extends RepositoryService<UserFile> {
  constructor(
    api: ApiService,
    private patientRepo: PatientRepository,
    private professionalRepo: ProfessionalRepository,
    private hospitalRepo: HospitalRepository,
    private painRepo: PainRepository,
    private serviceRepo: ServiceRepository,
    private divisionRepo: DivisionRepository,
  ) {
    super(api, 'user-files');
  }

  listIncoming(): Observable<UserFile[]> {
    return this.list({ state: 'sent' });
  }

  fromJson(obj: any): UserFile {
    const userfile = new UserFile();
    userfile.id = obj.id;
    userfile.state = obj.state;
    userfile.patient = this.patientRepo.fromJson(obj.user);
    userfile.hospital = this.hospitalRepo.fromJson(obj.hospital);
    userfile.pains = obj.pains.map((p: any) => this.painRepo.fromJson(p));
    userfile.service = this.serviceRepo.fromJson(obj.service);
    userfile.division = this.divisionRepo.fromJson(obj.division);
    userfile.priority = new Priority(obj.priority ?? 5);
    userfile.assignee = this.professionalRepo.fromJson(obj.assignee);
    userfile.arrivalDate = this.getRandomArrivalDate();
    userfile.painLevel = obj.painLevel;
    userfile.notes = obj.notes;
    userfile.refreshSymptomsFromPains();
    return userfile;
  }

  private getRandomArrivalDate() {
    const date = new Date();
    const nbMinutesRandom = Math.floor(Math.random() * (400 - 1 + 1)) + 1;
    date.setMinutes(date.getMinutes() - nbMinutesRandom);
    return date;
  }

  toJson(obj: UserFile): any {
    const jsonObject: any = obj.copy();
    console.log('userfile', obj);
    jsonObject.hospitalId = obj.hospital.id;
    if (obj.service?.id > 0) {
      jsonObject.serviceId = obj.service.id;
    }
    jsonObject.userId = obj.patient.id;
    jsonObject.priority = obj.priority.value;
    if (obj.division?.id > 0) {
      jsonObject.divisionId = obj.division.id;
    }
    if (obj.assignee?.id > 0) {
      jsonObject.assigneeId = obj.assignee.id;
    }
    jsonObject.painLevel = obj.painLevel;
    jsonObject.notes = obj.notes;
    return jsonObject;
  }
}
