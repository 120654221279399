import {UserFileModule} from './views/userfile/userfile.module';
import {HomeModule} from './views/home/home.module';
import {SharedModule} from './shared.module';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxUiLoaderHttpModule, NgxUiLoaderModule} from 'ngx-ui-loader';
import {HttpClientModule} from '@angular/common/http';
import {HeaderComponent} from './components/header/header.component';
import {NavMenuModule} from './components/nav-menu/nav-menu.module';

@NgModule({
  declarations: [AppComponent, HeaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    SharedModule,
    HomeModule,
    UserFileModule,
    HttpClientModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule,
    NavMenuModule,
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
