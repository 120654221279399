<div id="userfile-container" *ngIf="isDataAvailable" fxLayout="row" fxLayoutAlign="space-between stretch" fxFill>
  <div class="leftPanel" fxFlex="70">
    <app-profile-info [patient]="patient" [userfile]="userfile" (saveUserfile)="reloadUserfile()"
                      (tempSelectedSymptom)="onSymptomFocus($event)"></app-profile-info>
  </div>
  <div class="userfile-content" fxFlex>
    <app-pains-body-group [userfile]="userfile" resetButtonPosition="right"
                          [tempSelectedBodyPartCodes]="tempSelectedBodyPartCodes">
    </app-pains-body-group>
  </div>

</div>
