import {Symptom} from './symptom';
import {BodyPart} from './bodypart';
import {BaseModel} from './base';

export class Pain extends BaseModel {
  bodyPart: BodyPart;
  symptoms: Symptom[];

  public constructor(bodyPart?: BodyPart, symptoms?: Symptom[]) {
    super();
    this.bodyPart = bodyPart;
    this.symptoms = symptoms;
  }
}
