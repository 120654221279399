<div class="content" role="main" fxFill>
  <div fxLayout="row" fxFill fxLayoutAlign="start stretch">
    <app-nav-menu></app-nav-menu>
    <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex>
      <app-header></app-header>
      <div class="main-container" fxFlex>
        <router-outlet fxFill></router-outlet>
      </div>
    </div>
  </div>
  <ngx-ui-loader
    bgsColor="#615BBE"
    bgsPosition="center-center"
    bgsOpacity="1"
  ></ngx-ui-loader>
</div>
