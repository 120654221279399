import {Injectable, OnInit} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {BodyPartPicture} from '../models/bodypartpicture';
import {BodyPartPictureRepository} from '../repositories/bodypartpicture.repo';
import {Symptom} from '../models/symptom';
import {SymptomRepository} from '../repositories/symptom.repo';


@Injectable({
  providedIn: 'root',
})
export class BodyService {

  private bodyPartPictures: BodyPartPicture[] = [];
  symptoms: Symptom[] = [];

  constructor(private bodyPartPicturesRepo: BodyPartPictureRepository, private symptomRepo: SymptomRepository) {
  }

  async loadBodyPartPictures(): Promise<void> {
    if (this.bodyPartPictures.length === 0) {
      this.bodyPartPictures = await firstValueFrom(this.bodyPartPicturesRepo.list());
      this.bodyPartPictures = this.bodyPartPictures.sort((a, b) => a.compareTo(b));
    }
  }

  getBodyPartPictures(isMale: boolean, isRecto: boolean): BodyPartPicture[] {
    return this.bodyPartPictures.filter(bpp => bpp.isMale === isMale && bpp.isRecto === isRecto);
  }

  async loadSymptoms(): Promise<void> {
    if (this.symptoms.length === 0) {
      this.symptoms = await firstValueFrom(this.symptomRepo.list());
    }
  }


}
