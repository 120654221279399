import { BaseModel } from './base';

export class Address extends BaseModel {
  streetNumber: number;
  streetName: string;
  city: string;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;
}
