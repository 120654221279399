import {BaseModel} from './base';
import {BodyPart} from './bodypart';

export class BodyPartPicture extends BaseModel {
  code: string;
  isMale: boolean;
  isRecto: boolean;
  top: number;
  left: number;
  height: number;
  width: number;
  bodyPart: BodyPart;

  path(): string {
    const gender = this.isMale ? 'male' : 'female';
    const side = this.isRecto ? 'recto' : 'verso';

    return `${gender}/${side}/${this.code}.svg`;
  }

  compareTo(obj: BodyPartPicture): number {
    return this.bodyPart.compareTo(obj.bodyPart);
  }
}
