import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriorityBlocComponent } from './priority-bloc.component';
import { ProrityElementComponent } from './prority-element/prority-element.component';
import {SharedModule} from '../../shared.module';



@NgModule({
  declarations: [
    PriorityBlocComponent,
    ProrityElementComponent
  ],
  exports: [
    PriorityBlocComponent,
    ProrityElementComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class PriorityBlocModule { }
