import { AddressRepository } from './address.repo';
import { RepositoryService } from './base.repo';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { Hospital } from '../models/hospital';

@Injectable({
  providedIn: 'root',
})
export class HospitalRepository extends RepositoryService<Hospital> {
  constructor(api: ApiService, private addressRepo: AddressRepository) {
    super(api, 'hospitals');
  }

  fromJson(obj: any): Hospital {
    const hospital = new Hospital();
    hospital.id = obj.id;
    hospital.name = obj.name;
    hospital.address = obj.address && this.addressRepo.fromJson(obj.address);
    return hospital;
  }
  toJson(obj: Hospital) {
    throw new Error('Method not implemented.');
  }
}
