import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'nav-menu-button',
  templateUrl: './nav-menu-button.component.html',
  styleUrls: ['./nav-menu-button.component.css'],
})
export class NavMenuButtonComponent implements OnInit {
  @Input()
  icon: string;
  @Input()
  isActive = false;

  isHover = false;

  constructor() {
  }

  ngOnInit(): void {
  }
}
