import { Injectable } from '@angular/core';
import { UserFile } from '../models/userfile';
import { UserFileRepository } from '../repositories/userfile.repo';
import { LoggerService } from './logger.service';
import { lastValueFrom } from 'rxjs';
import { PatientRepository } from '../repositories/user.repo';

@Injectable({
  providedIn: 'root',
})
export class UserFileService {
  userfile: UserFile;

  tempUserfile: UserFile;

  constructor(
    private repoUserfile: UserFileRepository,
    private repoPatient: PatientRepository,
    private logger: LoggerService,
  ) {}

  async loadUserfile(id: number): Promise<UserFile> {
    const userfile$ = this.repoUserfile.findById(id);
    this.userfile = await lastValueFrom(userfile$);
    this.logger.debug('Userfile loaded', this.userfile);
    return this.userfile;
  }

  initTempUserFile() {
    this.tempUserfile = Object.assign(new UserFile(), this.userfile);
  }

  saveUserfile(): void {
    this.repoUserfile.save(this.userfile).then((_) => {});
  }

  saveUser(): void {
    if (this.userfile.patient.birthday) {
      const ageInMs = Date.now() - this.userfile.patient.birthday.getTime();
      this.userfile.patient.age = new Date(ageInMs).getFullYear() - 1970;
    }
    this.repoPatient.save(this.userfile.patient).then((_) => {});
  }
}
