import { Component, OnInit } from '@angular/core';
import { UserFileService } from '../../../../services/user-file.service';
import { UserFile } from '../../../../models/userfile';
import { Gender } from '../../../../models/user';

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.css'],
})
export class IdentityComponent implements OnInit {
  userfile: UserFile;

  constructor(private userFileService: UserFileService) {}

  ngOnInit(): void {
    this.userfile = this.userFileService.tempUserfile;
  }

  onClickGender(isMale: boolean) {
    this.userfile.patient.gender = isMale ? Gender.MALE : Gender.FEMALE;
  }

  onClickPregnant() {
    this.userfile.patient.pregnantWeeks = this.userfile.patient.isPregnant()
      ? null
      : 0;
  }

  birthdayChange(date: string): void {
    this.userfile.patient.birthday = new Date(date);
  }
}
