import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Priority} from '../../models/priority';

@Component({
  selector: 'app-priority-bloc',
  templateUrl: './priority-bloc.component.html',
  styleUrls: ['./priority-bloc.component.css']
})
export class PriorityBlocComponent {

  @Input() priority: Priority;
  @Output() priorityChange = new EventEmitter<Priority>();
  listPriorities = [1, 2, 3, 4, 5].map(val => new Priority(val));

  constructor() {
  }

  onClickPriority(priority: Priority) {
    this.priorityChange.emit(priority);
  }

}
