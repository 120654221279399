import { Symptom } from './../models/symptom';
import { RepositoryService } from './base.repo';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Address } from '../models/address';

@Injectable({
  providedIn: 'root',
})
export class SymptomRepository extends RepositoryService<Symptom> {
  constructor(api: ApiService) {
    super(api, 'symptoms');
  }

  fromJson(obj: any): Symptom {
    const symptom = new Symptom();
    symptom.id = obj.id;
    symptom.name = obj.name;
    symptom.code = obj.code;

    return symptom;
  }
  toJson(obj: Symptom) {
    throw new Error('Method not implemented.');
  }
}
