import { RepositoryService } from './base.repo';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Service } from '../models/service';

@Injectable({
  providedIn: 'root',
})
export class ServiceRepository extends RepositoryService<Service> {
  constructor(api: ApiService) {
    super(api, 'services');
  }

  fromJson(obj: any): Service {
    if (obj) {
      const service = new Service();
      service.id = obj.id;
      service.code = obj.code;
      service.name = obj.name;
      return service;
    } else {
      return null;
    }
  }

  toJson(obj: Service) {
    throw new Error('Method not implemented.');
  }
}
