import {SharedModule} from '../../shared.module';
import {NgModule} from '@angular/core';
import {HomeComponent} from './home.component';
import {GridUsersComponent} from './grid-users/grid-users.component';
import {CardUserComponent} from './grid-users/card-user/card-user.component';
import {ChipStatusModule} from '../../components/chip-status/chip-status.module';
import {NewPatientDialogComponent} from './new-patient-dialog/new-patient-dialog.component';
import {NewIdentityComponent} from './new-patient-dialog/new-identity/new-identity.component';

@NgModule({
  declarations: [
    HomeComponent,
    GridUsersComponent,
    CardUserComponent,
    NewPatientDialogComponent,
    NewIdentityComponent
  ],
  imports: [SharedModule, ChipStatusModule],
  exports: [HomeComponent],
})
export class HomeModule {
}
