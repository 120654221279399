<div id="identity-content" class="px-4" fxLayout="column" fxLayoutAlign="center stretch">
  <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="40px">
    <div fxLayout="column" fxFlex fxLayoutAlign="center stretch" fxLayoutGap="10px">
      <h3>Prénom</h3>
      <input class="bloc-container" [(ngModel)]="userfile.patient.firstName"/>
    </div>
    <div fxLayout="column" fxFlex fxLayoutAlign="center stretch" fxLayoutGap="10px">
      <h3>Sexe</h3>
      <div id="gender-bloc" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <div [ngClass]="['bloc-container', !userfile.patient.isMale() ? 'enabled' : 'disabled' ]"
             fxLayoutAlign="space-around center" fxLayout="column" fxFlex
             (click)="onClickGender(false)">
          <img
            src="../../../../../assets/images/userfile/gender/female{{ userfile.patient.isMale() ? '-disabled' : ''}}.svg"
            alt="Gender female"/>
          <h4>Femme</h4>
        </div>
        <div [ngClass]="['bloc-container', userfile.patient.isMale() ? 'enabled' : 'disabled' ]"
             fxLayout="column" fxFlex fxLayoutAlign="space-around center"
             (click)="onClickGender(true)">
          <img
            src="../../../../../assets/images/userfile/gender/male{{ userfile.patient.isMale() ? '' : '-disabled'}}.svg"
            alt="Gender male"/>
          <h4>Homme</h4>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="40px">
    <div fxLayout="column" fxFlex fxLayoutAlign="center stretch" fxLayoutGap="10px">
      <h3>Nom</h3>
      <input class="bloc-container" [(ngModel)]="userfile.patient.lastName"/>
    </div>
    <div fxFlex>
      <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" *ngIf="!userfile.patient.isMale()">
        <h3>Grossesse (semaines)</h3>
        <div fxLayout="row" fxFlex fxLayoutGap="10px">
          <div [ngClass]="['bloc-container', userfile.patient.isPregnant() ? 'enabled' : 'disabled' ]" fxFlex
               (click)="onClickPregnant()"
               fxLayoutAlign="center center">Enceinte
          </div>
          <div fxFlex>
            <input *ngIf="userfile.patient.isPregnant()" class="bloc-container" fxFlex
                   [(ngModel)]="userfile.patient.pregnantWeeks" type="number"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="40px">
    <div fxLayout="column" fxFlex fxLayoutAlign="center stretch" fxLayoutGap="10px">

      <h3>Date de naissance</h3>
      <div class="bloc-container" fxLayout="column">
        <input id="birthdayInput" type="date" class="birthdayInput"
               [ngModel]="userfile.patient.birthday | date:'yyyy-MM-dd'" (ngModelChange)="birthdayChange($event)"/>
      </div>
    </div>

    <div fxLayout="row" fxFlex fxLayoutGap="10px">
      <div fxLayout="column" fxFlex fxLayoutGap="10px">
        <h3>Taille (cm)</h3>
        <input class="bloc-container" [(ngModel)]="userfile.patient.size" type="number"/>
      </div>
      <div fxLayout="column" fxFlex fxLayoutGap="10px">
        <h3>Poids (kg)</h3>
        <input class="bloc-container" [(ngModel)]="userfile.patient.weight" type="number"/>
      </div>
    </div>
  </div>

</div>

