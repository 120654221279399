import {RepositoryService} from './base.repo';
import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';
import {BodyPartPicture} from '../models/bodypartpicture';
import {BodyPartRepository} from './bodypart.repo';

@Injectable({
  providedIn: 'root',
})
export class BodyPartPictureRepository extends RepositoryService<BodyPartPicture> {
  constructor(api: ApiService, private bodyPartRepo: BodyPartRepository) {
    super(api, 'body-part-pictures');
  }

  fromJson(obj: any): BodyPartPicture {
    const bodypartpicture = new BodyPartPicture();
    bodypartpicture.id = obj.id;
    bodypartpicture.isMale = obj.isMale;
    bodypartpicture.isRecto = obj.isRecto;
    bodypartpicture.code = obj.code;
    bodypartpicture.top = obj.top;
    bodypartpicture.left = obj.left;
    bodypartpicture.height = obj.height;
    bodypartpicture.width = obj.width;
    bodypartpicture.bodyPart = this.bodyPartRepo.fromJson(obj.bodyPart);

    return bodypartpicture;
  }

  toJson(obj: BodyPartPicture) {
    throw new Error('Method not implemented.');
  }
}
