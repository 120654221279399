import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavMenuComponent } from './nav-menu.component';
import { NavMenuButtonComponent } from './nav-menu-button/nav-menu-button.component';
import {SharedModule} from '../../shared.module';



@NgModule({
  declarations: [NavMenuComponent, NavMenuButtonComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [NavMenuComponent]
})
export class NavMenuModule { }
