import {WaitingStatus} from '../models/userfile';

export class CssHelper {

  static waitingStatusSuffixClass(status: WaitingStatus): string {
    switch (status) {
      case WaitingStatus.Short:
        return 'short';
      case WaitingStatus.Medium:
        return 'medium';
      case WaitingStatus.Long:
        return 'long';

    }
  }
}
