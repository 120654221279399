import {Component, Input, OnInit} from '@angular/core';
import {UserFile, WaitingStatus} from 'src/app/models/userfile';
import {Symptom} from '../../../../models/symptom';
import {CssHelper} from '../../../../helpers/css.helper';

@Component({
  selector: 'app-card-user',
  templateUrl: './card-user.component.html',
  styleUrls: ['./card-user.component.css'],
})
export class CardUserComponent implements OnInit {
  @Input()
  userfile: UserFile;
  @Input()
  infoToDisplay: CardUserInfoToDisplay;
  displayStatus: boolean;
  waitingStatusSuffixClass: string;

  constructor() {
  }

  ngOnInit(): void {
    this.displayStatus = this.infoToDisplay === CardUserInfoToDisplay.Status;
    this.waitingStatusSuffixClass = CssHelper.waitingStatusSuffixClass(this.userfile.getWaitingStatus());
  }

  getFirstSymptom(): Symptom {
    return this.userfile.pains.map((p) => p.symptoms[0])[0];
  }
}

export enum CardUserInfoToDisplay {
  Status, Details
}
