import {Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {BodyPartPicture} from '../../../models/bodypartpicture';
import {BodyPart} from '../../../models/bodypart';
import {PanZoomConfig} from '../pan-zoom/panzoom-config';
import {BodyService} from '../../../services/body.service';
import {PanZoomAPI} from '../pan-zoom/panzoom-api';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-pains-body',
  templateUrl: './pains-body.component.html',
  styleUrls: ['./pains-body.component.css']
})
export class PainsBodyComponent implements OnInit, OnDestroy {

  @Input() selectedBodyParts: BodyPart[] = [];
  @Input() tempSelectedBodyPartCodes: string[] = [];
  @Input() isMale = false;
  @Input() isRecto = false;
  @Input() isDraggable = true;
  @Input() resetButtonPosition = 'none';
  @Input() bodyPartSelected: BodyPart;
  @Output() bodyPartSelectedChange = new EventEmitter<BodyPart>();
  @Input() isSelectable = false;
  private zoomApiSubscription: Subscription;
  private panZoomAPI: PanZoomAPI;
  panZoomConfig: PanZoomConfig = new PanZoomConfig({
    neutralZoomLevel: 3,
    initialZoomLevel: 0,
    initialZoomToFit: {
      x: 0,
      y: 0,
      height: 3400,
      width: 1700
    },
    scalePerZoomLevel: 2,
    freeMouseWheel: false,
    invertMouseWheel: true,
    zoomOnDoubleClick: false
  });

  constructor(private bodyService: BodyService) {
  }

  async ngOnInit(): Promise<void> {
    this.panZoomConfig.panOnClickDrag = this.isDraggable;
    this.panZoomConfig.zoomOnMouseWheel = this.isDraggable;
    this.zoomApiSubscription = this.panZoomConfig.api.subscribe((api: PanZoomAPI) => this.panZoomAPI = api);
  }

  getBodyPartPictures() {
    return this.bodyService.getBodyPartPictures(this.isMale, this.isRecto);
  }

  isBodyPartPictureSelected(picture: BodyPartPicture): boolean {
    return this.selectedBodyParts.find(bp => bp.code === picture.bodyPart.code) !== undefined &&
      !this.isBodyPartPictureTempSelected(picture);
  }

  isBodyPartPictureTempSelected(picture: BodyPartPicture): boolean {
    return this.tempSelectedBodyPartCodes.includes(picture.bodyPart.code) || picture.bodyPart.code === this.bodyPartSelected?.code;
  }


  onClickBodyPartPicture(bodyPartPicture: BodyPartPicture) {
    // this.bodyPartSelected = bodyPartPicture.bodyPart;
    this.bodyPartSelectedChange.emit(bodyPartPicture.bodyPart);
  }

  onClickResetZoom() {
    this.panZoomAPI.resetView();
  }


  ngOnDestroy(): void {
    this.zoomApiSubscription.unsubscribe();
  }


}
