import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-bloc-info',
  templateUrl: './bloc-info.component.html',
  styleUrls: ['./bloc-info.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class BlocInfoComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() color?: 'purple' | 'orange' | 'violet' | 'red' | 'green';

  constructor() {}

  ngOnInit(): void {}
}
