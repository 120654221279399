import { NgModule } from '@angular/core';
import { PanZoomComponent } from './panzoom.component';

@NgModule({
  imports: [],
  declarations: [ PanZoomComponent ],
  providers: [],
  exports: [ PanZoomComponent ]
})

export class NgxPanZoomModule {}
