<div fxLayout="row" fxLayoutGap="20px">
  <app-antecedents-column title="Allergies" [antecedents]="userfile.patient.allergies()"
                          (addAntecedent)="addAntecedent($event)" (removeAntecedent)="removeAntecedent($event)"
                          [antecedentType]="typeAntecedentAllergy" fxFlex></app-antecedents-column>
  <app-antecedents-column title="Vaccins" [antecedents]="userfile.patient.vaccines()"
                          (addAntecedent)="addAntecedent($event)" (removeAntecedent)="removeAntecedent($event)"
                          [antecedentType]="typeAntecedentVaccine" fxFlex></app-antecedents-column>
  <app-antecedents-column title="Chirurgies" [antecedents]="userfile.patient.surgeries()"
                          (addAntecedent)="addAntecedent($event)" (removeAntecedent)="removeAntecedent($event)"
                          [antecedentType]="typeAntecedentSurgery" fxFlex></app-antecedents-column>
  <app-antecedents-column title="Médicaments" [antecedents]="userfile.patient.medications()"
                          (addAntecedent)="addAntecedent($event)" (removeAntecedent)="removeAntecedent($event)"
                          [antecedentType]="typeAntecedentMedication"
                          fxFlex></app-antecedents-column>
  <app-antecedents-column title="Addictions" [antecedents]="userfile.patient.addictions()"
                          (addAntecedent)="addAntecedent($event)" (removeAntecedent)="removeAntecedent($event)"
                          [antecedentType]="typeAntecedentAddiction"
                          fxFlex></app-antecedents-column>
</div>
