import { SymptomRepository } from './symptom.repo';
import { BodyPartRepository } from './bodypart.repo';
import { Pain } from './../models/pain';
import { RepositoryService } from './base.repo';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class PainRepository extends RepositoryService<Pain> {
  constructor(
    api: ApiService,
    private bodypartRepo: BodyPartRepository,
    private symptomRepo: SymptomRepository
  ) {
    super(api, 'pains');
  }

  fromJson(obj: any): Pain {
    const pain = new Pain();
    pain.id = obj.id;
    pain.bodyPart = this.bodypartRepo.fromJson(obj.bodyPart);
    pain.symptoms = obj.symptoms.map((s: any) => this.symptomRepo.fromJson(s));

    return pain;
  }
  toJson(obj: Pain) {
    throw new Error('Method not implemented.');
  }
}
