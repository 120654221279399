import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Antecedent, TypeAntecedent} from '../../../../../models/antecedent';
import {Priority} from '../../../../../models/priority';

@Component({
  selector: 'app-antecedents-column',
  templateUrl: './antecedents-column.component.html',
  styleUrls: ['./antecedents-column.component.css']
})
export class AntecedentsColumnComponent {

  newAntecedent: Antecedent;
  antecedentToDelete: Antecedent;

  @Input() antecedents: Antecedent[];
  @Output() addAntecedent = new EventEmitter<Antecedent>();
  @Output() removeAntecedent = new EventEmitter<Antecedent>();
  @Input() antecedentType: TypeAntecedent;
  @Input() title: string;

  onClickAddAntecedent(): void {
    this.newAntecedent = new Antecedent('Entrez le nom ...', this.antecedentType, new Date());
  }

  onClickDeleteAntecedent(antecedent: Antecedent): void {
    this.antecedentToDelete = antecedent;
  }

  validateDeleteAntecedent(): void {
    this.removeAntecedent.emit(this.antecedentToDelete);
    this.antecedentToDelete = null;
  }

  cancelDeleteAntecedent(): void {
    this.antecedentToDelete = null;
  }

  validateNewAntecendent(): void {
    this.addAntecedent.emit(this.newAntecedent);
    this.newAntecedent = null;
  }

  cancelNewAntecendent(): void {
    this.newAntecedent = null;
  }

  newAntecedentDateChange(date: string): void {
    this.newAntecedent.date = new Date(date);
  }

}
