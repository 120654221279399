<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
  <h3>{{title}}</h3>
  <div *ngFor="let antecedent of antecedents" class="bloc-container" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="start start" fxFlex>
      <h4>{{antecedent.name}}</h4>
      <span>{{antecedent.date.toLocaleDateString()}}</span>
    </div>
    <img *ngIf="antecedent != antecedentToDelete" id="deleteIcon" src="./assets/images/icons/others/delete.svg" alt="Supprimer l'antécédent" (click)="onClickDeleteAntecedent(antecedent)"/>
    <div *ngIf="antecedent == antecedentToDelete" fxLayout="row">
      <mat-icon class="check-validate" (click)="validateDeleteAntecedent()">check</mat-icon>
      <mat-icon class="cross-cancel" (click)="cancelDeleteAntecedent()">close</mat-icon>
    </div>
  </div>
  <div *ngIf="newAntecedent" class="bloc-container" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" fxLayoutAlign="start start" fxFlex>
      <input id="newAntecedentNameInput" class="newAntecedentInput" [(ngModel)]="newAntecedent.name"/>
      <input id="newAntecedentDateInput" type="date" class="newAntecedentInput"
             [ngModel]="newAntecedent.date | date:'yyyy-MM-dd'" (ngModelChange)="newAntecedentDateChange($event)"/>
    </div>
    <mat-icon class="check-validate" (click)="validateNewAntecendent()">check</mat-icon>
    <mat-icon class="cross-cancel" (click)="cancelNewAntecendent()">close</mat-icon>
  </div>
  <div class="add-button" fxLayoutAlign="center center" (click)="onClickAddAntecedent()">
    <mat-icon>add</mat-icon>
  </div>
</div>
