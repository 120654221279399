<div fxFill style="position: relative">
  <div id="pains-body">
    <pan-zoom [config]="panZoomConfig">

      <img *ngFor="let picture of getBodyPartPictures()" [alt]="picture.code"
           [ngClass]="{ 'selectable': isDraggable && picture.bodyPart.selectable && isSelectable,
           'selected': isBodyPartPictureSelected(picture),
           'temp-selected': isBodyPartPictureTempSelected(picture) }"
           [src]="'./assets/images/body/original/' + picture.path()"
           [style.height]="picture.height + 'px'" (click)="onClickBodyPartPicture(picture)"
           [style.width]="picture.width + 'px'"
           [style.top]="(3400 - picture.top) + 'px'"
           [style.left]="picture.left + 'px'"/>

    </pan-zoom>
  </div>
  <button *ngIf="isDraggable" mat-fab id="reset-button" class="reset-button-{{resetButtonPosition}}" color="primary"
          (click)="onClickResetZoom()">
    <mat-icon>center_focus_weak</mat-icon>
  </button>
</div>


