<div id="profile-info-container" fxLayout="column" fxLayoutGap="15px" fxFill style="height: 100vh">

  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
    <div fxLayout="row" fxFlex="50" fxLayoutAlign="space-between end" fxLayoutGap="10px">
      <h3 fxFlex>Informations personnelles</h3>
      <div id="dmp-button" class="icon-button">
        <img src="./assets/images/userfile/dmp.svg" alt="DMP"/>
      </div>
      <div id="social-card-button" class="icon-button">
        <div id="yellow-tick-social-card"></div>
      </div>
    </div>
    <div fxLayout="row" fxFlex="50" fxLayoutAlign="space-between end">
      <h3>Département médical</h3>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center">
          <span>En attente depuis : </span>
          <span
            [ngClass]="['time-value', 'time-value-' + waitingStatusSuffixClass]">{{ userfile.getDurationString() }} </span>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center">
          <span>Arrivé à : </span>
          <span class="time-value">{{ userfile.getArrivalDateString() }} </span>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
    <div id="bloc-info-patient" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" fxFlex>
      <div id="bloc-name" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between stretch"
           (click)="clicOnBloc('identity')">
        <app-bloc-info fxFlex label="Prénom">
          <h3>{{ patient.firstName }}</h3>
        </app-bloc-info>
        <app-bloc-info fxFlex label="Nom">
          <h3>{{ patient.lastName }}</h3>
        </app-bloc-info>
      </div>
      <div id="bloc-patient-details" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between stretch"
           (click)="clicOnBloc('identity')">
        <app-bloc-info fxFlex label="Sexe">
          <img src="./assets/images/userfile/gender/{{ patient.gender }}.svg" alt="Gender {{ patient.gender }}"/>
        </app-bloc-info>
        <app-bloc-info fxFlex *ngIf="!patient.isMale() && patient.pregnantWeeks > 0" label="Enceinte">
          <h3>{{ patient.pregnantWeeks.toString() + ' sem' }}</h3>
        </app-bloc-info>
        <app-bloc-info fxFlex [label]="patient.birthday.toLocaleDateString()">
          <h3>{{ patient.age.toString() + ' ans' }}</h3>
        </app-bloc-info>
        <app-bloc-info fxFlex label="Taille">
          <h3>{{ patient.size.toString()+ ' cm' }}</h3>
        </app-bloc-info>
        <app-bloc-info fxFlex label="Poids">
          <h3>{{ patient.weight.toString()+ ' kg' }}</h3>
        </app-bloc-info>
      </div>
    </div>
    <div id="bloc-departement-medical" fxLayout="row" fxLayoutGap="20px" fxFlex>
      <div fxLayout="column" fxLayoutGap="20px" fxFlex (click)="clicOnBloc('management')">
        <app-bloc-info fxFlex label="Filière">
          <h3>{{ userfile.division?.name ?? "Pas de division" }}</h3>
        </app-bloc-info>
        <app-bloc-info fxFlex label="Département">
          <h3>{{ userfile.service?.name ?? "Pas de service" }}</h3>
        </app-bloc-info>
      </div>

      <div class="bloc-info" id="priority-bloc" [style.background-color]="userfile.priority.color" fxFlex="15"
           (click)="clicOnBloc('management')"
           fxLayoutAlign="center center">
        <h2>{{ 'P' + userfile.priority.value }}</h2>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="15px" fxFlex fxLayoutAlign="start stretch"
         (click)="clicOnBloc('antecedents')">
      <h3>Antécédent(s)</h3>
      <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
        <app-bloc-info fxFlex label="Allerg." color="purple" [disabled]="userfile.patient.allergies().length == 0">
          <h2>{{ userfile.patient.allergies().length }}</h2>
        </app-bloc-info>
        <app-bloc-info fxFlex label="Vacc." color="red" [disabled]="userfile.patient.vaccines().length == 0">
          <h2>{{ userfile.patient.vaccines().length }}</h2>
        </app-bloc-info>
        <app-bloc-info fxFlex label="Chirurg." color="orange" [disabled]="userfile.patient.surgeries().length == 0">
          <h2>{{ userfile.patient.surgeries().length }}</h2>
        </app-bloc-info>
        <app-bloc-info fxFlex label="Médic." color="green" [disabled]="userfile.patient.medications().length == 0">
          <h2>{{ userfile.patient.medications().length }}</h2>
        </app-bloc-info>
        <app-bloc-info fxFlex label="Addict." color="violet" [disabled]="userfile.patient.addictions().length == 0">
          <h2>{{ userfile.patient.addictions().length }}</h2>
        </app-bloc-info>
      </div>
    </div>
    <div id="bloc-ide" fxFlex fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px"
         (click)="clicOnBloc('management')">
      <h3>IDE en poste</h3>
      <div class="bloc-info" fxFlex fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px">
        <div fxFlex fxLayout="column" fxLayoutAlign="center start">
          <h3>{{ userfile.assignee?.displayName() ?? "A sélectionner" }}</h3>
          <span>3 internes en renfort</span>
        </div>
        <div fxLayout="column" fxLayoutAlign="center start">
          <span>Salle</span>
          <h3>105B</h3>
        </div>
      </div>
    </div>
  </div>

  <h3>Symptômes</h3>
  <div fxLayout="row" fxLayoutGap="20px">
    <div fxFlex (click)="clicOnBloc('symptoms')">
      <div id="bloc-symptoms" class="bloc-info" fxFlex fxLayout="row" fxLayoutAlign="start center"
           fxLayoutGap="15px" (click)="clicOnBloc('symptoms')">
        <div *ngFor="let symptom of userfile.symptoms.slice(0,3)" fxLayout="column" fxLayoutAlign="space-between center"
             fxLayoutGap="5px">
          <span class="symptom-name" fxFlex fxLayoutAlign="center center">{{ symptom.name }}</span>
          <img class="symptom-icon" src="./assets/images/icons/symptoms/{{symptom.code}}.svg" alt="{{symptom.code}}"
               (mouseenter)="focusInSymptom(symptom)" (mouseleave)="focusOutSymptom()"/>
        </div>
        <div *ngIf="userfile.symptoms.length > 3" fxLayout="column" fxLayoutAlign="space-between center"
             fxLayoutGap="5px" fxFlex>
          <span class="symptom-name" fxFlex fxLayoutAlign="center center">Autres</span>
          <h3 fxLayoutAlign="center center" class="symptom-icon">{{ userfile.symptoms.length - 3 }}+</h3>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="15px" fxFlex fxLayoutAlign="start stretch"
         (click)="clicOnBloc('symptoms')">
      <div fxLayout="row" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutGap="15px" fxFlex>
          <app-bloc-info label="Présence de la douleur"><h3>Continue</h3></app-bloc-info>
          <app-bloc-info label="Récurrence de la douleur"><h3>2 fois par heure</h3></app-bloc-info>
        </div>
        <div class="bloc-info" id="bloc-pain-level" fxLayout="column" fxLayoutAlign="space-between stretch"
             (click)="clicOnBloc('symptoms')">
          <span>Douleur</span>
          <h2>{{ userfile.painLevel }}</h2>
          <div id="pain-level-indicator" [className]="'pain-level-' + getColorPainLevelClass()"></div>
        </div>
      </div>
    </div>
  </div>

  <div id="notes" class="bloc-info"
       fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px" fxFlex>
    <div fxLayout="row">
      <h3 fxFlex>Notes</h3>
      <mat-icon class="pointer" (click)="clicOnBloc('notes')">chevron_right</mat-icon>
    </div>
    <textarea fxFlex (focusout)="onFocusOutNotes()" [(ngModel)]="userfile.notes"></textarea>
  </div>

</div>
