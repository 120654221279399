import {Component, OnInit} from '@angular/core';
import {UserFile} from '../../../../models/userfile';
import {UserFileService} from '../../../../services/user-file.service';
import {BodyPart} from '../../../../models/bodypart';
import {BodyService} from '../../../../services/body.service';
import {Symptom} from '../../../../models/symptom';

@Component({
  selector: 'app-symptoms',
  templateUrl: './symptoms.component.html',
  styleUrls: ['./symptoms.component.css']
})
export class SymptomsComponent implements OnInit {

  userfile: UserFile;
  bodyPartSelected: BodyPart;
  tempSymptomsSelected: Symptom[] = [];

  constructor(private userFileService: UserFileService, public bodyService: BodyService) {
  }

  ngOnInit(): void {
    this.userfile = this.userFileService.tempUserfile;
  }

  selectBodyPart(bodyPart: BodyPart) {
    this.bodyPartSelected = bodyPart;
    this.tempSymptomsSelected = this.symptomsFromBodyPart(bodyPart);
  }

  unselectBodyPart() {
    this.resetSelection();
  }

  resetSelection() {
    this.tempSymptomsSelected = [];
    this.bodyPartSelected = null;
  }

  isSymptomSelected(bodyPart: BodyPart, symptom: Symptom): boolean {
    return this.tempSymptomsSelected.some(s => s.code === symptom.code);
  }

  symptomsFromBodyPart(bodyPart: BodyPart): Symptom[] {
    return this.userfile.pains.find(pain => pain.bodyPart.code === bodyPart.code)?.symptoms ?? [];
  }

  selectSymptom(symptom: Symptom) {
    if (this.isSymptomSelected(this.bodyPartSelected, symptom)) {
      this.tempSymptomsSelected = this.tempSymptomsSelected.filter(s => s.code !== symptom.code);
    } else {
      this.tempSymptomsSelected.push(symptom);
    }
  }

  validateSymptoms(bodyPart: BodyPart) {
    this.userfile.setSymptoms(bodyPart, this.tempSymptomsSelected);
    this.resetSelection();
  }
}
