import { RepositoryService } from './base.repo';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Division } from '../models/division';

@Injectable({
  providedIn: 'root',
})
export class DivisionRepository extends RepositoryService<Division> {
  constructor(api: ApiService) {
    super(api, 'divisions');
  }

  fromJson(obj: any): Division {
    if (obj) {
      const division = new Division();
      division.id = obj.id;
      division.code = obj.code;
      division.name = obj.name;
      return division;
    } else {
      return null;
    }
  }

  toJson(obj: Division) {
    throw new Error('Method not implemented.');
  }
}
