import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chip-status',
  templateUrl: './chip-status.component.html',
  styleUrls: ['./chip-status.component.css']
})
export class ChipStatusComponent implements OnInit {

  @Input()
  isPresent: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  getLabel(): string {
    return this.isPresent ? 'Présent' : 'En chemin';
  }

}
