import { Address } from './address';
import { BaseModel } from './base';
import { Antecedent, TypeAntecedent } from './antecedent';

export abstract class User extends BaseModel {
  firstName: string;
  lastName: string;
  email: string;
  gender: Gender;
  age: number;
  birthday: Date;
  phone: string;
  address: Address;
  isAnonymous: boolean;
  pregnantWeeks: number;
  isPregnant = () =>
    this.gender === Gender.FEMALE && this.pregnantWeeks !== null;

  isMale(): boolean {
    return this.gender === Gender.MALE;
  }

  genderToString() {
    return this.isMale() ? 'Homme' : 'Femme';
  }

  abstract displayName(): string;
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export class Patient extends User {
  size: number;
  weight: number;
  antecedents: Antecedent[];

  constructor() {
    super();
    this.antecedents = [];
  }

  surgeries(): Antecedent[] {
    return this.filterAntecedents(TypeAntecedent.SURGERY);
  }

  vaccines(): Antecedent[] {
    return this.filterAntecedents(TypeAntecedent.VACCINE);
  }

  allergies(): Antecedent[] {
    return this.filterAntecedents(TypeAntecedent.ALLERGY);
  }

  medications(): Antecedent[] {
    return this.filterAntecedents(TypeAntecedent.MEDICATION);
  }

  addictions(): Antecedent[] {
    return this.filterAntecedents(TypeAntecedent.ADDICTION);
  }

  displayName(): string {
    return this.isAnonymous
      ? `Patient #${this.id}`
      : this.firstName + ' ' + this.lastName;
  }

  resetIsAnonymous() {
    this.isAnonymous = !(
      this.firstName?.length > 0 || this.lastName?.length > 0
    );
  }

  private filterAntecedents(typeAntecedent: TypeAntecedent) {
    return this.antecedents
      .filter((a) => a.type === typeAntecedent)
      .sort((a, b) => b.date.getTime() - a.date.getTime());
  }
}

export class Professional extends User {
  constructor() {
    super();
    this.isAnonymous = false;
  }

  displayName(): string {
    return this.firstName + ' ' + this.lastName;
  }
}
