import {BaseModel} from './base';

export class Priority extends BaseModel {
  value: number;
  color: string;

  constructor(value: number) {
    super();
    this.value = value;

    switch (this.value) {
      case 1:
        this.color = '#FF5151';
        break;
      case 2:
        this.color = '#FF9800';
        break;
      case 3:
        this.color = '#FFE250';
        break;
      case 4:
        this.color = '#adff84';
        break;
      default:
        this.color = '#7590FF';
        break;
    }
  }

  static list(): Priority[] {
    return [new Priority(1), new Priority(2), new Priority(3), new Priority(4), new Priority(5),];
  }
}
