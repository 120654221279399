import {BodyPart} from '../models/bodypart';
import {RepositoryService} from './base.repo';
import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class BodyPartRepository extends RepositoryService<BodyPart> {
  constructor(api: ApiService) {
    super(api, 'body-parts');
  }

  fromJson(obj: any): BodyPart {
    const bodypart = new BodyPart();
    bodypart.id = obj.id;
    bodypart.name = obj.name;
    bodypart.code = obj.code;
    bodypart.order = obj.order;
    bodypart.selectable = obj.selectable;
    return bodypart;
  }

  toJson(obj: BodyPart) {
    throw new Error('Method not implemented.');
  }

}
