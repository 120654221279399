<div id="dialog" fxLayout="row" fxFill fxLayoutAlign="start stretch">
  <div fxFlex="20" fxLayout="column" class="nav-menu" fxLayoutGap="20px">
    <div id="patient-bloc" fxLayout="column">
      <span>Edition de la fiche patient de :</span>
    </div>
    <a [routerLink]="[{outlets: {'userfile-new': ['identity']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Informations personnelles</a>
    <a [routerLink]="[{outlets: {'userfile-new': ['antecedents']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Antécédents</a>
    <a [routerLink]="[{outlets: {'userfile-new': ['pain']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Douleur</a>
    <a [routerLink]="[{outlets: {'userfile-new': ['symptoms']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Symptômes</a>
    <a [routerLink]="[{outlets: {'userfile-new': ['evaluation']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Evaluation</a>
    <a [routerLink]="[{outlets: {'userfile-new': ['summary']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Récapitulatif</a>
  </div>
  <div class="divider-vertical"></div>
  <div fxFlex fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayoutAlign="end end">
      <mat-icon class="icon">close</mat-icon>
    </div>
    <div class="content" fxFlex>
      <router-outlet name="userfile-new"></router-outlet>
    </div>
    <app-button size="large">Valider</app-button>
  </div>
</div>
