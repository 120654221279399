<div id="dialog" fxLayout="row" fxFill fxLayoutAlign="start stretch">
  <div fxFlex="20" fxLayout="column" class="nav-menu" fxLayoutGap="20px">
    <div id="patient-bloc" fxLayout="column">
      <span >Edition de la fiche patient de :</span>
      <h3>{{userfile.patient.displayName()}}</h3>
    </div>
    <a [routerLink]="[{outlets: {'userfile-edit': ['identity']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Informations personnelles</a>
    <a [routerLink]="[{outlets: {'userfile-edit': ['symptoms']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Symptômes</a>
    <a [routerLink]="[{outlets: {'userfile-edit': ['management']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Prise en charge</a>
    <a [routerLink]="[{outlets: {'userfile-edit': ['antecedents']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Antécédents</a>
    <a [routerLink]="[{outlets: {'userfile-edit': ['notes']}}]" [queryParams]="{dialog: true}"
       routerLinkActive="is-active">Notes</a>
  </div>
  <div class="divider-vertical"></div>
  <div fxFlex fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayoutAlign="end end">
      <mat-icon class="icon" (click)="closeDialog()">close</mat-icon>
    </div>
    <div class="content" fxFlex>
      <router-outlet name="userfile-edit"></router-outlet>
    </div>
    <app-button size="large" (onClick)="validateDialog()">Valider</app-button>
  </div>
</div>
