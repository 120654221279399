import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoggerService } from './logger.service';

// we can now access environment.apiUrl
const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private logger: LoggerService,
  ) {}

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  findById(route: string, id: number): Observable<any> {
    return this.http
      .get(this.getFullPath(route) + '/' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  get(route: string, id: number): Observable<any> {
    return this.http
      .get<any>(this.getFullPath(route) + '/' + id)
      .pipe(retry(1), catchError(this.handleError));
  }

  list(route: string, params: any = {}): Observable<any[]> {
    this.httpOptions[`params`] = new HttpParams({ fromObject: params });

    return this.http
      .get<any[]>(this.getFullPath(route), this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  create(route: string, object: any): Observable<any> {
    return this.http
      .post(this.getFullPath(route), object, this.httpOptions)
      .pipe(retry(1), catchError(this.handleError));
  }

  update(route: string, object: any): Observable<any> {
    this.logger.debug('Update object', object);
    return this.http
      .patch<any>(
        this.getFullPath(route) + '/' + object.id,
        object,
        this.httpOptions,
      )
      .pipe(retry(1), catchError(this.handleError));
  }

  // Error handling
  handleError(error: {
    error: { message: string };
    status: any;
    message: any;
  }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // this.logger.error(`Error on API : ${errorMessage}`);
    return throwError(errorMessage);
  }

  private getFullPath(route: string): string {
    return API_URL + '/api/' + route;
  }
}
