<div fxLayout="row" fxFlexFill>
  <div fxFlex="20"></div>
  <div fxFlex="60" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start stretch">
    <h3>Département médical</h3>
    <div fxLayout="row" fxLayoutGap="20px">
      <div fxFlex fxLayout="column" fxLayoutGap="20px">
        <div class="bloc-container" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <img class="icon-square" src="./assets/images/icons/services/COURT.svg" alt="court"/>
          <div fxFlex><select [ngModel]="userfile.division?.code" (change)="onOptionSelectDivision($event)">
            <option *ngIf="!divisions || divisions.length === 0" disabled value="">{{ 'pas de division' }}</option>
            <option *ngFor="let division of divisions" [value]="division.code">{{ division.name }}</option>
          </select></div>
          <span>Filière</span>
        </div>
        <div class="bloc-container" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
          <img class="icon-square" src="./assets/images/icons/divisions/TRAUM.svg" alt="court"/>
          <div fxFlex>
            <select [ngModel]="userfile.service?.code" (change)="onOptionSelectService($event)">
              <option *ngFor="let service of services" [value]="service.code">{{ service.name }}</option>
            </select>
          </div>
          <span>Service</span>
        </div>
      </div>
      <app-priority-bloc [(priority)]="userfile.priority"></app-priority-bloc>
    </div>
    <div class="spacer-10"></div>
    <h3>IDE en poste</h3>
    <div class="bloc-container" fxLayoutAlign="start center">
      <h3>{{userfile.assignee?.displayName()}}</h3>
    </div>
    <div class="spacer-10"></div>
    <h3>Salle</h3>
  </div>
  <div fxFlex="20"></div>
</div>
