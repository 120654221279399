import { SharedModule } from '../../shared.module';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { NgModule } from '@angular/core';
import { UserFileComponent } from './userfile.component';
import { ChipStatusModule } from '../../components/chip-status/chip-status.module';
import { PriorityBlocModule } from '../../components/priority-bloc/priority-bloc.module';
import { IdentityComponent } from './edit-dialog/identity/identity.component';
import { SymptomsComponent } from './edit-dialog/symptoms/symptoms.component';
import { PainsBodyGroupModule } from '../../components/pains-body-group/pains-body-group.module';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { FormsModule } from '@angular/forms';
import { ManagementComponent } from './edit-dialog/management/management.component';
import { AntecedentsComponent } from './edit-dialog/antecedents/antecedents.component';
import { NotesComponent } from './edit-dialog/notes/notes.component';
import { AntecedentsColumnComponent } from './edit-dialog/antecedents/antecedents-column/antecedents-column.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BlocInfoComponent } from './profile-info/bloc-info/bloc-info.component';

@NgModule({
  declarations: [
    UserFileComponent,
    ProfileInfoComponent,
    IdentityComponent,
    SymptomsComponent,
    EditDialogComponent,
    ManagementComponent,
    AntecedentsComponent,
    NotesComponent,
    AntecedentsColumnComponent,
    BlocInfoComponent,
  ],
  imports: [
    SharedModule,
    ChipStatusModule,
    PriorityBlocModule,
    PainsBodyGroupModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [UserFileComponent],
  providers: [MatDatepickerModule],
})
export class UserFileModule {}
