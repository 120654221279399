<div id="home-container" fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="space-between stretch" fxFill>

  <app-grid-users fxFlex [userfiles]="userfilesInComing" title="Utilisateurs de SMD"
                  [infoToDisplay]="INFO_TO_DISPLAY_STATUS">
  </app-grid-users>

  <div class="divider"></div>

  <app-grid-users fxFlex [userfiles]="userfilesGeneral" title="Attente générale"
                  [infoToDisplay]="INFO_TO_DISPLAY_DETAILS">
  </app-grid-users>

  <div class="divider"></div>

  <app-grid-users fxFlex [userfiles]="userfilesShort" title="Filière courte" [infoToDisplay]="INFO_TO_DISPLAY_DETAILS">
  </app-grid-users>

  <div class="divider"></div>

  <app-grid-users fxFlex [userfiles]="userfilesChir" title="Filière médico-chirurgicale"
                  [infoToDisplay]="INFO_TO_DISPLAY_DETAILS">
  </app-grid-users>


  <button mat-fab id="fab-add-patient" color="primary" (click)="openNewPatientDialog('identity')">
    <mat-icon>add</mat-icon>
  </button>
</div>

