import {Component, OnInit, Input} from '@angular/core';
import {UserFile} from 'src/app/models/userfile';
import {CardUserInfoToDisplay} from './card-user/card-user.component';

@Component({
  selector: 'app-grid-users',
  templateUrl: './grid-users.component.html',
  styleUrls: ['./grid-users.component.css'],
})
export class GridUsersComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  userfiles: UserFile[] = [];
  @Input()
  infoToDisplay: CardUserInfoToDisplay;

  constructor() {
  }

  ngOnInit(): void {
  }
}
