import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserFile} from '../../models/userfile';
import {BodyPart} from '../../models/bodypart';

@Component({
  selector: 'app-pains-body-group',
  templateUrl: './pains-body-group.component.html',
  styleUrls: ['./pains-body-group.component.css']
})
export class PainsBodyGroupComponent {

  @Input() userfile: UserFile;
  showRecto = true;
  @Input() tempSelectedBodyPartCodes: string[] = [];
  @Input() resetButtonPosition = 'none';

  @Input() bodyPartSelected: BodyPart;
  @Output() bodyPartSelectedChange = new EventEmitter<BodyPart>();
  @Input() isSelectable = false;

  constructor() {
  }

  reverseBody() {
    this.showRecto = !this.showRecto;
  }

  onBodyPartSelectedChange(bodyPart: BodyPart) {
    this.bodyPartSelectedChange.emit(bodyPart);
  }
}
