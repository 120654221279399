import { Component, OnInit } from '@angular/core';
import { UserFileService } from '../../../services/user-file.service';
import { MatDialog } from '@angular/material/dialog';
import { UserFile } from '../../../models/userfile';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.css'],
})
export class EditDialogComponent implements OnInit {
  userfile: UserFile;

  constructor(
    private userFileService: UserFileService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.userfile = this.userFileService.userfile;
  }

  validateDialog() {
    this.userFileService.userfile = this.userFileService.tempUserfile;
    this.userFileService.userfile.patient.resetIsAnonymous();
    this.userFileService.saveUserfile();
    this.userFileService.saveUser();
    this.closeDialog();
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
