import { Component, OnInit } from '@angular/core';
import { UserFile } from 'src/app/models/userfile';
import { UserFileRepository } from 'src/app/repositories/userfile.repo';
import { CardUserInfoToDisplay } from './grid-users/card-user/card-user.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewIdentityComponent } from './new-patient-dialog/new-identity/new-identity.component';
import { NewPatientDialogComponent } from './new-patient-dialog/new-patient-dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  userfiles: UserFile[] = [];
  userfilesInComing: UserFile[] = [];
  userfilesGeneral: UserFile[] = [];
  userfilesShort: UserFile[] = [];
  userfilesChir: UserFile[] = [];
  INFO_TO_DISPLAY_STATUS = CardUserInfoToDisplay.Status;
  INFO_TO_DISPLAY_DETAILS = CardUserInfoToDisplay.Details;
  routeQueryParams$: Subscription;

  constructor(
    private repo: UserFileRepository,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.routeQueryParams$ = route.queryParams.subscribe((params) => {
      if (params.dialog) {
        this.openDialog();
      }
    });
  }

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers() {
    return this.repo.listIncoming().subscribe((data) => {
      this.userfiles = data;
      this.userfilesInComing = this.userfiles.filter(
        (u) => u.division?.code === 'SMD' || u.division == null,
      );
      this.userfilesGeneral = this.userfiles.filter(
        (u) => u.division?.code === 'GEN',
      );
      this.userfilesShort = this.userfiles.filter(
        (u) => u.division?.code === 'COURTE',
      );
      this.userfilesChir = this.userfiles.filter(
        (u) => u.division?.code === 'CHIR',
      );
    });
  }

  openNewPatientDialog(subpage?: string): void {
    const outletSubpage = subpage ?? 'identity-new';
    this.router.navigate([{ outlets: { 'userfile-new': outletSubpage } }], {
      relativeTo: this.route.parent,
      queryParams: { dialog: true },
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NewPatientDialogComponent, {
      width: '80%',
      height: '80%',
      panelClass: 'userfile-new-dialog',
      data: {},
    });

    dialogRef.afterClosed().subscribe((_) => {
      this.router
        .navigate([{ outlets: { 'userfile-new': null } }], {
          relativeTo: this.route.parent,
        })
        .then();
    });
  }
}
