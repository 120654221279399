export abstract class BaseModel {
  id: number;

  constructor() {
    this.id = 0;
  }

  copy() {
    return JSON.parse(JSON.stringify(this));
  }
}
