import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-new-patient-dialog',
  templateUrl: './new-patient-dialog.component.html',
  styleUrls: ['./new-patient-dialog.component.css']
})
export class NewPatientDialogComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
