import { RepositoryService } from './base.repo';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Address } from '../models/address';

@Injectable({
  providedIn: 'root',
})
export class AddressRepository extends RepositoryService<Address> {
  constructor(api: ApiService) {
    super(api, 'addresses');
  }

  fromJson(obj: any): Address {
    const address = new Address();
    address.id = obj.id;
    address.streetNumber = obj.streetNumber;
    address.streetName = obj.streetName;
    address.city = obj.city;
    address.country = obj.country;
    address.postalCode = obj.postalCode;
    address.latitude = obj.latitude;
    address.longitude = obj.longitude;

    return address;
  }
  toJson(obj: Address) {
    throw new Error('Method not implemented.');
  }
}
