<div class="bloc-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
  <div class="header-title" fxFlex>
    <h3>Gestion patients</h3>
  </div>
  <div class="header-right-bloc" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <h3>Clinique du Millénaire</h3>
    <div class="circle-chip-user">
      <span>AP</span>
    </div>
    <div fxLayout="column">
      <span class="user-name">Alain PERATIF</span>
      <span class="user-role">Admin</span>
    </div>

  </div>
</div>
