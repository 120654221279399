import {Component, Output, OnInit, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() size = 'infinite';
  @Input() type = 'primary';
  @Output() onClick = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onClickButton() {
    this.onClick.emit();
  }

}
