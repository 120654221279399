import { BaseModel } from './base';
import moment from 'moment/moment';

export class Antecedent extends BaseModel {
  name: string;
  type: TypeAntecedent;
  date: Date;

  constructor(name?: string, type?: TypeAntecedent, date?: Date) {
    super();
    this.name = name;
    this.type = type;
    this.date = date;
  }
}

export enum TypeAntecedent {
  ALLERGY = 'allergy',
  SURGERY = 'surgery',
  VACCINE = 'vaccine',
  MEDICATION = 'medication',
  ADDICTION = 'addiction',
}
