<div id="userfile-content-container" fxFill>
  <app-pains-body [isMale]="userfile.patient?.isMale()" [isRecto]="this.showRecto"
                  [bodyPartSelected]="bodyPartSelected" (bodyPartSelectedChange)="onBodyPartSelectedChange($event)"
                  [selectedBodyParts]="userfile.selectedBodyParts()" [resetButtonPosition]="resetButtonPosition"
                  [isSelectable]="isSelectable"
                  [tempSelectedBodyPartCodes]="tempSelectedBodyPartCodes"></app-pains-body>

  <div class="footer-div" (click)="reverseBody()">
    <app-pains-body [isMale]="userfile.patient?.isMale()" [isRecto]="!this.showRecto"
                    [selectedBodyParts]="userfile.selectedBodyParts()"
                    [tempSelectedBodyPartCodes]="tempSelectedBodyPartCodes"
                    [isDraggable]="false"
    ></app-pains-body>
  </div>
</div>
