import { Pain } from './pain';
import { Hospital } from './hospital';
import { Patient, Professional } from './user';
import { BaseModel } from './base';
import { Service } from './service';
import { Priority } from './priority';
import { Division } from './division';
import { Symptom } from './symptom';
import { BodyPart } from './bodypart';

export class UserFile extends BaseModel {
  state: string;
  patient: Patient;
  hospital: Hospital;
  distanceHospital: number;
  painLevel: number;
  pains: Pain[];
  symptoms: Symptom[];
  service: Service = null;
  division: Division = null;
  priority: Priority = new Priority(5);
  assignee: Professional = null;
  arrivalDate: Date;
  notes: string;

  getWaitingDuration() {
    return new Date().getTime() / 1000 - this.arrivalDate.getTime() / 1000;
  }

  refreshSymptomsFromPains() {
    const symptoms = this.pains.reduce((allSymptoms, pain) => {
      return allSymptoms.concat(pain.symptoms);
    }, []);

    const distinctSymptoms: Symptom[] = Array.from(
      symptoms
        .reduce((distinct, symptom) => {
          distinct.set(symptom.code, symptom);
          return distinct;
        }, new Map<string, Symptom>())
        .values(),
    );

    distinctSymptoms.forEach((symptom) => {
      const bodyParts = this.pains
        .filter(
          (pain) =>
            pain.symptoms.filter((s) => s.code === symptom.code).length > 0,
        )
        .map((_) => _.bodyPart);

      symptom.bodyParts = Array.from(
        bodyParts
          .reduce((distinct, bodyPart) => {
            distinct.set(bodyPart.code, bodyPart);
            return distinct;
          }, new Map<string, BodyPart>())
          .values(),
      );
    });
    this.symptoms = distinctSymptoms;
  }

  getWaitingStatus() {
    if (this.getWaitingDuration() < 3600) {
      return WaitingStatus.Short;
    } else if (
      this.getWaitingDuration() >= 3600 &&
      this.getWaitingDuration() < 7200
    ) {
      return WaitingStatus.Medium;
    } else {
      return WaitingStatus.Long;
    }
  }

  secondsToTimeFormat(durationInSeconds: number): string {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const hoursString = hours < 10 ? `0${hours}` : hours.toString();
    const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
    return `${hoursString}:${minutesString}`;
  }

  getArrivalDateString() {
    return this.arrivalDate.toLocaleTimeString('fr-FR', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  getDurationString() {
    return this.secondsToTimeFormat(this.getWaitingDuration());
  }

  selectedBodyParts(): BodyPart[] {
    return this.pains?.map((p) => p.bodyPart);
  }

  setSymptoms(bodyPart: BodyPart, symptoms: Symptom[]) {
    const pain = this.pains.find((p) => p.bodyPart.code === bodyPart.code);
    if (pain) {
      if (symptoms.length > 0) {
        pain.symptoms = symptoms;
      } else {
        this.pains = this.pains.filter(
          (p) => p.bodyPart.code !== bodyPart.code,
        );
      }
    } else {
      if (symptoms.length > 0) {
        this.pains.push(new Pain(bodyPart, symptoms));
      }
    }
    this.refreshSymptomsFromPains();
  }
}

export enum WaitingStatus {
  Short,
  Medium,
  Long,
}
