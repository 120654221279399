import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private readonly logLevel: LogLevel = LogLevel.None;

  constructor() {
    if (environment.logLevel) {
      this.logLevel = environment.logLevel;
    }
  }

  info(msg: string, data?: any): void {
    this.log(LogLevel.Info, msg, data);
  }

  warn(msg: string, data?: any): void {
    this.log(LogLevel.Warning, msg, data);
  }

  error(msg: string, data?: any): void {
    this.log(LogLevel.Error, msg, data);
  }

  debug(msg: string, data?: any): void {
    this.log(LogLevel.Debug, msg, data);
  }

  private log(level: LogLevel, msg: string, data: any): void {
    msg = '%c' + '[SMD] ' + msg;
    const color = 'color : ' + this.getColor(level);
    if (this.shouldLog(level)) {
      switch (level) {
        case LogLevel.Error:
          return data ? console.error(msg, color, data) : console.error(msg, color);
        case LogLevel.Warning:
          return data ? console.warn(msg, color, data) : console.warn(msg, color);
        case LogLevel.Info:
          return data ? console.info(msg, color, data) : console.info(msg, color);
        default:
          return data ? console.debug(msg, color, data) : console.debug(msg, color);
      }
    }
  }

  private getColor(level: LogLevel) {
    switch (level) {
      case LogLevel.Error:
        return 'red';
      case LogLevel.Warning:
        return 'orange';
      case LogLevel.Info:
        return 'blue';
      default:
        return 'green';
    }
  }

  private shouldLog(level: LogLevel) {
    if (this.logLevel === LogLevel.None) {
      return false;
    } else if (this.logLevel === LogLevel.Error) {
      return level === LogLevel.Error;
    } else if (this.logLevel === LogLevel.Warning) {
      return level === LogLevel.Error || level === LogLevel.Warning;
    } else if (this.logLevel === LogLevel.Info) {
      return level === LogLevel.Error || level === LogLevel.Warning || level === LogLevel.Info;
    } else if (this.logLevel === LogLevel.Debug) {
      return level === LogLevel.Error || level === LogLevel.Warning || level === LogLevel.Info || level === LogLevel.Debug;
    } else {
      return true;
    }
  }
}

class LogLevel {
  public static None = 'none';
  public static Debug = 'debug';
  public static Info = 'info';
  public static Warning = 'warning';
  public static Error = 'error';

}

