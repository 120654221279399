import { UserFileComponent } from './views/userfile/userfile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { HomeComponent } from './views/home/home.component';
import { IdentityComponent } from './views/userfile/edit-dialog/identity/identity.component';
import { SymptomsComponent } from './views/userfile/edit-dialog/symptoms/symptoms.component';
import { ManagementComponent } from './views/userfile/edit-dialog/management/management.component';
import { AntecedentsComponent } from './views/userfile/edit-dialog/antecedents/antecedents.component';
import { NotesComponent } from './views/userfile/edit-dialog/notes/notes.component';
import { NewIdentityComponent } from './views/home/new-patient-dialog/new-identity/new-identity.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'userfile/:id', component: UserFileComponent },
  { path: '', component: IdentityComponent, outlet: 'userfile-edit' },
  { path: 'identity', component: IdentityComponent, outlet: 'userfile-edit' },
  { path: 'symptoms', component: SymptomsComponent, outlet: 'userfile-edit' },
  {
    path: 'management',
    component: ManagementComponent,
    outlet: 'userfile-edit',
  },
  {
    path: 'antecedents',
    component: AntecedentsComponent,
    outlet: 'userfile-edit',
  },
  { path: 'notes', component: NotesComponent, outlet: 'userfile-edit' },
  { path: '', component: NewIdentityComponent, outlet: 'userfile-new' },
  { path: 'identity', component: NewIdentityComponent, outlet: 'userfile-new' },
  {
    path: 'antecedents',
    component: NewIdentityComponent,
    outlet: 'userfile-new',
  },
  { path: 'pain', component: NewIdentityComponent, outlet: 'userfile-new' },
  { path: 'symptoms', component: NewIdentityComponent, outlet: 'userfile-new' },
  {
    path: 'evaluation',
    component: NewIdentityComponent,
    outlet: 'userfile-new',
  },
  { path: 'summary', component: NewIdentityComponent, outlet: 'userfile-new' },
];

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
