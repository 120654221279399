import { RepositoryService } from './base.repo';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Patient, Professional } from '../models/user';
import { AntecedentRepository } from './antecedent.repo';

@Injectable({
  providedIn: 'root',
})
export class PatientRepository extends RepositoryService<Patient> {
  constructor(
    api: ApiService,
    private antecedentRepo: AntecedentRepository,
  ) {
    super(api, 'users');
  }

  fromJson(obj: any): Patient {
    const patient = new Patient();

    if (obj) {
      patient.id = obj.id;
      patient.firstName = obj.firstName;
      patient.lastName = obj.lastName;
      patient.gender = obj.gender;
      patient.age = obj.age;
      patient.size = obj.size;
      patient.weight = obj.weight;
      patient.birthday = new Date(obj.birthday);
      patient.email = obj.email;
      patient.phone = obj.phone;
      patient.isAnonymous = obj.isAnonymous;
      patient.antecedents = obj.antecedents.map((a: any) =>
        this.antecedentRepo.fromJson(a),
      );
      patient.pregnantWeeks = obj.pregnantWeeks;
    }
    return patient;
  }

  toJson(obj: Patient): any {
    const jsonObject: any = obj.copy();
    jsonObject.firstName = obj.firstName;
    jsonObject.lastName = obj.lastName;
    jsonObject.gender = obj.gender;
    jsonObject.age = obj.age;
    jsonObject.size = obj.size;
    jsonObject.weight = obj.weight;
    jsonObject.birthday = obj.birthday;
    jsonObject.email = obj.email;
    jsonObject.phone = obj.phone;
    jsonObject.isAnonymous = obj.isAnonymous;
    jsonObject.pregnantWeeks = obj.pregnantWeeks;
    return jsonObject;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProfessionalRepository extends RepositoryService<Professional> {
  constructor(api: ApiService) {
    super(api, 'users');
  }

  fromJson(obj: any): Professional {
    if (obj) {
      const professional = new Professional();
      professional.id = obj.id;
      professional.firstName = obj.firstName;
      professional.lastName = obj.lastName;
      professional.gender = obj.gender;
      professional.age = obj.age;
      professional.birthday = new Date(obj.birthday);
      professional.email = obj.email;
      professional.phone = obj.phone;
      return professional;
    } else {
      return null;
    }
  }

  toJson(obj: Professional): any {
    const jsonObject: any = obj.copy();
    jsonObject.firstName = obj.firstName;
    jsonObject.lastName = obj.lastName;
    jsonObject.gender = obj.gender;
    jsonObject.age = obj.age;
    jsonObject.birthday = obj.birthday;
    jsonObject.email = obj.email;
    jsonObject.phone = obj.phone;
    jsonObject.isAnonymous = obj.isAnonymous;
    return jsonObject;
  }
}
