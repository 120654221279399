import { Component, OnInit } from '@angular/core';
import {UserFile} from '../../../../models/userfile';
import {UserFileService} from '../../../../services/user-file.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

  userfile: UserFile;

  constructor(private userfileService: UserFileService) {
  }

  ngOnInit(): void {
    this.userfile = this.userfileService.tempUserfile;
  }

}
