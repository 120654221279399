import {BaseModel} from './base';

export class Division extends BaseModel {
  name: string;
  code: string;
  order: number;

  constructor() {
    super();
    this.name = 'Utilisateurs de SMD';
    this.code = 'SMD';
  }
}
