import { UserFile } from '../../models/userfile';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Patient } from 'src/app/models/user';
import { Symptom } from '../../models/symptom';
import { UserFileService } from '../../services/user-file.service';

@Component({
  selector: 'app-userfile',
  templateUrl: './userfile.component.html',
  styleUrls: ['./userfile.component.css'],
})
export class UserFileComponent implements OnInit, OnDestroy {
  id: number;
  userfile: UserFile;
  patient: Patient;
  private sub: any;

  isDataAvailable = false;

  tempSelectedBodyPartCodes = [];

  constructor(
    private route: ActivatedRoute,
    private userfileService: UserFileService,
  ) {}

  ngOnInit() {
    this.userfile = new UserFile();
    this.patient = new Patient();
    this.sub = this.route.params.subscribe(async (params) => {
      this.userfile = await this.userfileService.loadUserfile(params.id);
      this.patient = this.userfile.patient;
      this.isDataAvailable = true;
    });
  }

  reloadUserfile() {
    this.userfile = this.userfileService.userfile;
    this.patient = this.userfile.patient;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onSymptomFocus(symptom: Symptom) {
    if (symptom) {
      this.tempSelectedBodyPartCodes = symptom.bodyParts.map((bp) => bp.code);
    } else {
      this.tempSelectedBodyPartCodes = [];
    }
  }
}
