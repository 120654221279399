<div fxFill fxLayout="row" fxLayoutAlign="start stretch">
  <app-pains-body-group [userfile]="userfile" fxFlex="60" [bodyPartSelected]="bodyPartSelected"
                        (bodyPartSelectedChange)="selectBodyPart($event)" [isSelectable]="true"
                        resetButtonPosition="left"></app-pains-body-group>
  <div fxFlex="40">
    <div *ngIf="!bodyPartSelected" fxLayout="column" fxLayoutAlign="start stretch" fxFlex fxLayoutGap="10px">
      <div *ngFor="let symptom of userfile.symptoms" class="bloc-container"
           fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <img class="symptom-icon" src="./assets/images/icons/symptoms/{{symptom.code}}.svg" alt="{{symptom.code}}"/>
        <div fxFlex fxLayout="column" fxLayoutAlign="start start">
          <div><h4>{{symptom.name}}</h4></div>
          <div class="symptom-body-part" *ngFor="let bodyPart of symptom.bodyParts">
            <span>{{bodyPart.name}}</span>
          </div>
        </div>
        <div class="symptoms-count-indic" fxLayoutAlign="center center">
          <h4>{{symptom.bodyParts.length}}</h4>
        </div>
      </div>
    </div>

    <div *ngIf="bodyPartSelected" fxFlex class="bloc-container padding-30" fxLayout="column"
         fxLayoutAlign="start stretch"
         fxLayoutGap="10px">
      <span>Vous avez séléctionné :</span>
      <h4>{{bodyPartSelected.name}}</h4>
      <div class="divider-horizontal"></div>
      <div fxFlex id="grid-symptoms">
        <mat-grid-list fxFlex cols="3" rowHeight="5:4">
          <mat-grid-tile *ngFor="let symptom of bodyService.symptoms">
            <div class="symptom-selection" fxFill>
              <div class="symptom" fxLayout="column" fxLayoutAlign="start center">
                <div class="symptom-image-group" (click)="selectSymptom(symptom)">
                  <img *ngIf="isSymptomSelected(bodyPartSelected, symptom)" class="selected-chip"
                       src="assets/images/icons/others/check.svg" alt="check selected"/>
                  <img class="symptom-icon"
                       [ngClass]="{ 'symptom-icon-selected' : isSymptomSelected(bodyPartSelected, symptom) }"
                       src="assets/images/icons/symptoms/{{symptom.code}}.svg"
                       alt="{{symptom.name}}"/>
                </div>
                <span>{{symptom.name}}</span>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <app-button fxFlex size="infinite" type="secondary" (onClick)="unselectBodyPart()">Annuler</app-button>
        <app-button fxFlex size="infinite" (onClick)="validateSymptoms(bodyPartSelected)">Valider</app-button>
      </div>
    </div>
  </div>
</div>
