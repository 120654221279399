<div class="card-incoming-user" [routerLink]="['/userfile', userfile.id]">
  <div fxLayout="row" fxFlex fxLayoutAlign="start stretch">
    <div class="left-side-card"
         [style.background-color]="displayStatus ? 'var(--smd-seal-color)' : userfile.priority.color">
      <div class="left-side-icon"  >
        <img *ngIf="displayStatus" src="./assets/images/icons/home/walking-man.svg" alt="flag icon">
        <img *ngIf="!displayStatus" class="symptom-thumbnail" src="./assets/images/icons/symptoms/{{getFirstSymptom().code}}.svg" alt="flag icon">
      </div>
    </div>
    <div class="right-side-card" fxLayout="column" fxFlex>
      <h3 class="incoming-user-name">{{userfile.patient.displayName()}}</h3>
      <app-chip-status *ngIf="displayStatus" [isPresent]="true"></app-chip-status>
      <div *ngIf="!displayStatus" class="user-details" fxLayout="row" fxLayoutGap="10px"  fxLayoutAlign="space-between center">
        <div fxFlex id="assignee-bloc" fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="space-between center">
          <img src="./assets/images/icons/home/stetoscope.svg" alt="flag icon">
          <span fxFlex class="info-span">{{ userfile.assignee.lastName }}</span>
        </div>
        <div id="waiting-time-bloc" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
          <img src="./assets/images/icons/home/clock.svg" alt="flag icon">
          <span [ngClass]="['span-waiting', 'span-waiting-' + waitingStatusSuffixClass]" class="info-span"> {{ userfile.getDurationString()}} </span>
          <div [ngClass]="['pin-waiting', 'pin-waiting-' + waitingStatusSuffixClass]">
            <img src="./assets/images/icons/home/waiting.svg" alt="flag icon">
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
