import {Component, OnInit} from '@angular/core';
import {BodyService} from './services/body.service';
import {LoggerService} from './services/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'smd-hospital';

  constructor(private bodyService: BodyService, private logger: LoggerService) {
  }

  async ngOnInit(): Promise<void> {
    this.logger.info('Init app');
    await this.bodyService.loadSymptoms();
    return this.bodyService.loadBodyPartPictures();
  }


}
